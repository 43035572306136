import React from "react";
import { Helmet } from "react-helmet";

// markup
const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>bucher.cloud</title>
      </Helmet>
      <div className="grid min-h-screen place-items-center bg-stone-800">
        <h1 className="animate-move-bg bg-gradient-to-r from-indigo-500 via-pink-500 to-indigo-500 bg-[length:400%] bg-clip-text text-9xl font-bold text-transparent">
          Hello :)
        </h1>
      </div>
    </main>
  );
};

export default IndexPage;
